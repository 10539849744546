import {
  clearClient,
  clearCurrentEvent,
  loadCurrentEvent,
  setClient,
  storeCurrentEvent,
  useAppDispatch,
  useAppSelector,
  useChooseYourGroup,
} from "@constituentvoice/cv-elements/web";
import { useEffect, useLayoutEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useGetGroups } from "../hooks/use-get-groups";
import { useUrlClientId } from "../hooks/use-url-hooks";

export const ClientListLayout = () => {
  const { previousEvents, upcomingEvents, isLoading, error } =
    useChooseYourGroup();
  const urlClientId = useUrlClientId();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const env = useAppSelector((state) => state.env.env);
  const currentEvent = useAppSelector(
    (state) => state.currentEvent.currentEvent,
  );
  const currentEventId = useAppSelector(
    (state) => state.currentEvent.currentEventId,
  );

  useLayoutEffect(() => {
    // Clear the client on load
    dispatch(clearClient());
    void dispatch(loadCurrentEvent());
  }, [dispatch]);

  useEffect(() => {
    if (error || isLoading) return;

    if (!previousEvents && !upcomingEvents) {
      // User has no groups
      dispatch(clearClient());
      navigate("/");
      return;
    }

    // If the current event Id does exist in async storage, use it to determine which event to display
    // Otherwise, pick the first event that is a part of the organization
    const matchedGroup = currentEventId
      ? [...previousEvents, ...upcomingEvents].find(
          (event) =>
            event.organization.serviceCode === urlClientId &&
            currentEventId === `${event.id}`,
        )
      : [...previousEvents, ...upcomingEvents].find(
          (event) => event.organization.serviceCode === urlClientId,
        );

    if (!matchedGroup) {
      // User has typed invalid URL
      navigate("/");
      return;
    }

    const { organization, ...restOfEvent } = matchedGroup;

    void dispatch(setClient(matchedGroup.organization));
    void dispatch(storeCurrentEvent(restOfEvent));
  }, [
    dispatch,
    isLoading,
    previousEvents,
    upcomingEvents,
    navigate,
    urlClientId,
    currentEventId,
    error,
  ]);

  if (!env) return <p>Loading env...</p>;

  if (error) {
    return <p>There was an error</p>;
  }

  if (isLoading) {
    return <p>Loading groups...</p>;
  }

  if (!client) {
    return <p>Loading client...</p>;
  }

  return <Outlet />;
};
